import React, { useState, useEffect } from 'react';
import './NewCss.css'; // Custom styling
import { Oval } from 'react-loader-spinner';
import paperPlane from '../static/images/paper-plane.png'; // Import the image
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Assessment from './Assessment';

const ChatAssessment = () => {
    const [question, setQuestion] = useState('');
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [askedQuestion, setAskedQuestion] = useState('');
    const [references, setReferences] = useState([]);
    const [chatHistory, setChatHistory] = useState([]);  // Chat history of selected chat
    const [chatIds, setChatIds] = useState([]); // List of all chat IDs
    const [selectedChatId, setSelectedChatId] = useState(null);
    const useremail = localStorage.getItem('useremail');
    const sessionid = localStorage.getItem('token');
    const filename = localStorage.getItem('filename');
    console.log(filename);

    // Modal state for showing/hiding
    const [showModal, setShowModal] = useState(false);



    // Fetch the chat IDs for the useremail
    useEffect(() => {
        const fetchChatIds = async () => {
            try {
                const response = await fetch(`https://asknature.ai/qauser/chats/${useremail}`);
                if (response.ok) {
                    const data = await response.json();
                    setChatIds(data.chat_ids); // Store chat IDs in chatIds
                       // Select the last chat ID from the list
                       if (data.chat_ids && data.chat_ids.length > 0) {
                        const lastChatId = data.chat_ids[data.chat_ids.length - 1];
                        setSelectedChatId(lastChatId);
                        handleChatSelect(lastChatId) // Set the last chat ID as selected
                        // Set the last chat ID as selected
                    }
                } else {
                    throw new Error('Failed to fetch chat IDs');
                }
            } catch (error) {
                console.error('Error fetching chat IDs:', error);
            }
        };

        if (useremail) {
            fetchChatIds();
        }
    }, [useremail]);

    // Fetch chat history for a selected chat_id
    const fetchChatHistoryById = async (chat_id) => {
        try {
            const response = await fetch(`https://asknature.ai/qauser/chat/${chat_id}/history/`);
            if (response.ok) {
                const data = await response.json();
                setChatHistory(data.chat_history);  // Set chat history for selected chat
            } else {
                throw new Error('Failed to fetch chat history');
            }
        } catch (error) {
            console.error('Error fetching chat history by ID:', error);
        }
    };

    // Handle sending a new query
    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            question: question,
            similarity_top_k: 10,
            useremail: useremail,
            sessionid: sessionid
            
        };
        setLoading(true);
        try {
            const response = await fetch(`https://asknature.ai/qauser/query/continue/${selectedChatId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`Failed to send message: ${response.statusText}`);
            }

            const responseData = await response.json();
            const fullResponse = responseData["Response from LLM"];
            const [mainResponse, ...referenceParts] = fullResponse.split('References:');
            const formattedReferences = referenceParts.join('References:').split('\n')
                .filter(ref => ref.trim().startsWith('['))
                .map(ref => {
                    const urlMatch = ref.match(/(https?:\/\/[^\s]+)/);
                    return urlMatch ? { text: ref.trim(), url: urlMatch[0] } : null;
                }).filter(ref => ref !== null);

            setResponse(mainResponse.trim());
            setReferences(formattedReferences);
            await fetchChatHistoryById(selectedChatId);

            // Clear the input after sending
            setAskedQuestion(question);
            setQuestion('');
        } catch (err) {
            console.error('Error:', err.message || err);
        } finally {
            setLoading(false);
        }
    };

    // Handle key press for Enter
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    // Handle selecting a chat
    const handleChatSelect = (chatId) => {
        setSelectedChatId(chatId);
        fetchChatHistoryById(chatId); // Fetch chat history by selected chat ID
    };

    // Handle new chat creation
    const handleNewChat = async () => {
        try {
            const data = {
                question: "",
                similarity_top_k: 10,
                useremail: useremail,
                sessionid: sessionid
            };

            const response = await fetch(`https://asknature.ai/qauser/query/continue/34r7987`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                const data = await response.json();
                const newChatId = data.chat_id;
                setChatIds(prevChatIds => [newChatId, ...prevChatIds]); // Add new chatId to the list
                setSelectedChatId(newChatId);
                await fetchChatHistoryById(newChatId);

                // Select new chat
                setAskedQuestion('');
                setResponse(null);
                setReferences([]);
                setChatHistory([]); // Clear chat history for the new chat
            } else {
                throw new Error('Failed to start a new chat');
            }
        } catch (error) {
            console.error('Error starting new chat:', error);
        }
    };

    // Open Modal
    const handleShareButtonClick = () => {
        setShowModal(true); // Show modal
    };

    // Close Modal
    const handleCloseModal = () => {
        setShowModal(false); // Hide modal
    };

    // Function to copy the current chat URL
    const handleCopyUrl = () => {
        const url = `${window.location.origin}/chat/${selectedChatId}`; // Construct the URL with selectedChatId
        navigator.clipboard.writeText(url)
            .then(() => {
                alert('URL copied to clipboard!');
                setShowModal(false); // Close modal after copying
            })
            .catch(err => {
                console.error('Error copying URL:', err);
            });
    };

    return (
        <div className="chatApp">
            {/* Sidebar showing chat history */}
            <div className="chatSidebar">
                <button onClick={handleNewChat} className="newChatBtn btn btn-primary">New Chat</button>
                <ul>
                    {chatIds.slice().reverse().map((chatId, index) => (
                        <li
                            key={index}
                            onClick={() => handleChatSelect(chatId)}
                            className={`chatHistoryItem ${chatId === selectedChatId ? 'selectedID' : ''}`}
                        >
                            Chat ID: {chatId}
                        </li>
                    ))}
                </ul>
            </div>

            {/* Main Chatbox */}
            <div className="chatContainer">
            <button onClick={handleShareButtonClick} className="shareBtn btn-primary">Share</button>
            <Assessment/>

                {/* React-Bootstrap Modal */}
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Share this chat</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input 
                            type="text" 
                            className="form-control" 
                            value={`${window.location.origin}/regulatory/${selectedChatId}`} 
                            readOnly 
                        />
                    </Modal.Body>
                    <Modal.Footer>
                    
                    <Button variant=" shareBtn btn-primary" onClick={handleCopyUrl}>
                            Copy URL
                        </Button>
                    </Modal.Footer>
                </Modal>
                               {loading &&    <div className='loadingbutton'><Oval height={100} width={100} color="#4fa94d" />
                                        </div>}
                <div className="chatHistoryContainer">
                    {chatHistory.length > 0 && (
                        <div className="messageContainer">
                            {chatHistory.map((item, index) => (
                <div key={item.id || index} className={`messageBubble ${index === chatHistory.length-1 ? 'noitem' : ''}`}>
                                    {/* Render query for user */}
                                    <div className={`userBubble ${item.query.length <= 1 ? 'noitem' : ''}`}>
                                        <strong>You:</strong>
                                        <span>{item.query}</span>
                                    </div>

                                    {/* Render reply for assistant */}
                                    <div className="assistantBubble">
                                        <strong>Assistant:</strong>
                                        <span>{item.reply}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

              
                <form className="chatInputForm" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Ask your questions from TNFD."
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        onKeyPress={handleKeyPress}
                        className="chatInput"
                    />
                    <img src={paperPlane} className="submitBtn" onClick={handleSubmit} alt="Submit" />
                </form>
            </div>
        </div>
    );
};

export default ChatAssessment;  // Default export
