import React, { useState, useEffect } from 'react';
import './NewCss.css'; // Custom styling
import { Oval } from 'react-loader-spinner';
import { useParams } from "react-router-dom"; // Import useParams to get URL parameters

const ChatHistory = () => {

    const [loading, setLoading] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);  // Chat history of selected chat
    const chat_id = useParams().chat_id; // Correct way to access chat_id from URL params

    // Fetch the chat history for the selected chat_id
    useEffect(() => {
        const fetchChatHistoryById = async () => {
            try {
                const response = await fetch(`https://asknature.ai/qa/chat/${chat_id}/history/`);
                if (response.ok) {
                    const data = await response.json();
                    console.log('Fetched chat history details:', data.chat_history);
                    setChatHistory(data.chat_history);  // Set chat history for selected chat
                } else {
                    throw new Error('Failed to fetch chat history');
                }
            } catch (error) {
                console.error('Error fetching chat history by ID:', error);
            }
        };

        if (chat_id) { // Only fetch if chat_id exists
            fetchChatHistoryById();
        }

    }, [chat_id]); // Dependency on chat_id so it refetches when the chat_id changes

    return (
        <div className="chatApp mx-auto">
            {/* Main Chatbox */}
            <div className="chatContainer mx-auto">
                <div className="chatHistoryContainer">
                    {chatHistory.length > 0 ? (
                        <div className="messageContainer">
                            {chatHistory.map((item, index) => (
                                <div key={index} className={`messageBubble`}>
                                    {/* Render query for user */}
                                    <div className={`userBubble ${item.query.length <= 1 ? 'noitem' : ''}`}>
                                        <strong>You:</strong>
                                        <span>{item.query}</span> {/* Show query for user */}
                                    </div>

                                    {/* Render reply for assistant */}
                                    <div className='assistantBubble'>
                                        <strong>Assistant:</strong>
                                        <span>{item.reply}</span> {/* Show reply for assistant */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>No chat history available</p> // Display if there is no chat history
                    )}
                </div>

                {loading && (
                    <div className="loadingIndicator">
                        <Oval
                            height={50}
                            width={50}
                            color="#4fa94d"
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="#4fa94d"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChatHistory;
