import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import asknaturelogo from '../static/images/asknaturelogo.png';

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        try {
            const response = await axios.post('https://asknature.ai/auth/reset-password/', {
                token: token,
                new_password: newPassword,
            });
            setMessage(response.data.message);
            setError('');
            // Redirect to login page after 2 seconds
            setTimeout(() => {
                navigate('/signin');
            }, 2000);
        } catch (err) {
            console.error(err);
            setError('Error resetting password. The link may have expired.');
            setMessage('');
        }
    };

    return (
        <div className="container">
        <div className="row justify-content-center align-items-start">
        <a href="/dashboard">  <img src={asknaturelogo} alt="AskNature Logo" 
        style={{ width: '150px', height: 'auto' }} 
/>
    </a>
            <div className="col-lg-6 specialbox">
            <h1>Reset Password</h1>
            <form onSubmit={handleSubmit}>
            <div className="mb-3">
                  <label>New Password:</label>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                        className="form-control"

                    />
                </div>
                <div className="mb-3">
                    <label>Confirm Password:</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        className="form-control"

                    />
                </div>
                <button type="submit" className='btn btn-primary'>Reset Password</button>
            </form>
            {message && <div>{message}</div>}
            {error && <div>{error}</div>}
        </div>
        </div>
        </div>
    );
};

export default ResetPassword;
