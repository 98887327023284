import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../static/Dashboard.css";
import "../static/Secondchatbox.css";
import { useAuth } from "../AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import asknaturelogo from "../static/images/asknaturelogo.png";
import { faBars, faUser, faHome, faExclamationTriangle, faDatabase } from "@fortawesome/free-solid-svg-icons";
import paperPlane from '../static/images/paper-plane.png'; // Import the image
import { Oval } from 'react-loader-spinner';

const questions = {
  tnfd_1: "How does the board oversee nature-related dependencies, impacts, risks and opportunities?",
  tnfd_2: "What is management's role in assessing and managing nature-related dependencies, impacts, risks and opportunities?",
  tnfd_3: "What are the organization's human rights policies and engagement activities regarding Indigenous Peoples, Local Communities, and stakeholders in relation to nature-related issues? How do the board and management oversee these?",
  tnfd_4: "What nature-related dependencies, impacts, risks and opportunities has the organization identified across different time horizons (short, medium, long term)?",
  tnfd_5: "How have nature-related dependencies, impacts, risks and opportunities affected the organization's business model, value chain, strategy and financial planning? What transition plans or analyses are in place?",
  tnfd_6: "How resilient is the organization's strategy to nature-related risks and opportunities under different scenarios?",
  tnfd_7: "What are the locations of assets/activities in direct operations and value chains that meet priority location criteria?",
  tnfd_8: "What processes does the organization use to identify, assess and prioritize nature-related dependencies, impacts, risks and opportunities in its direct operations?",
  tnfd_9: "What processes does the organization use to identify, assess and prioritize nature-related dependencies, impacts, risks and opportunities in its upstream and downstream value chains?",
  tnfd_10: "How does the organization manage nature-related dependencies, impacts, risks and opportunities?",
  tnfd_11: "How are the processes for identifying, assessing, prioritizing and monitoring nature-related risks integrated into overall risk management?",
  tnfd_12: "What metrics does the organization use to assess and manage material nature-related risks and opportunities in alignment with strategy and risk management?",
  tnfd_13: "What metrics does the organization use to assess and manage its dependencies and impacts on nature?",
  tnfd_14: "What targets and goals does the organization use to manage nature-related dependencies, impacts, risks and opportunities? How is it performing against these?",
};

const Assessment = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const filename = localStorage.getItem("filename");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedKey, setSelectedKey] = useState(""); // Default selected key
  const [message, setMessage] = useState('');
  const useremail = localStorage.getItem('useremail');
  const sessionid = localStorage.getItem('token');
  const [askedquestion, setAskedquestion] = useState('');
  const [response, setResponse] = useState('');
  const [references, setReferences] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [basic, setBasic] = useState(null);
  
console.log(filename);


const fetchBasicAlignment = async () => {
  const formData = new FormData();
  formData.append("filename", filename);
  formData.append("similarity_top_k", "6");

  const requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow"
  };
  try {
    const response = await fetch("https://asknature.ai/basicalignment/basic_alignment", requestOptions);
    const secondresult = await response.json(); // Parse JSON response
    console.log("Basic Alignment Result:", secondresult);
    setBasic(secondresult); // Store parsed JSON in state
  } catch (error) {
    console.error("Error fetching basic alignment data:", error);
  }
};

// Call this function in useEffect or an appropriate place
useEffect(() => {
  fetchBasicAlignment();
}, []);



  




  useEffect(() => {
    const fetchData = async () => {
      const formData = new FormData();
      formData.append("filename", filename);
      formData.append("similarity_top_k", "6");

      const requestOptions = {
        method: "POST",
        body: formData,
      };

 


      try {
        const response = await fetch(
          "https://asknature.ai/assessmentalignment/assessment_alignment",
          //"http://127.0.0.1:8009/assessment_alignment",
          requestOptions
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const result = await response.json();
        setData(result);
        console.log(result);

        // Set the first key as the default selected option
        const firstKey = Object.keys(result.GENERATED_ASSESSMENT)[0];
        setSelectedKey(firstKey);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [filename]);

  useEffect(() => {
    if (data && Object.keys(data.GENERATED_ANSWERS).length > 0) {
      // Set the first question as default when data is loaded
      const firstKey = Object.keys(data.GENERATED_ANSWERS)[0];
      setSelectedKey(firstKey);
    }
  }, [data]);

  const handleOptionChange = (e) => {
    setSelectedKey(e.target.value); // Update selected question
  };

  return (
    <div>

        {loading && <div className=" loadingbutton">
                                      <Oval
                                          height={100}
                                          width={100}
                                          color="#4fa94d"
                                          wrapperStyle={{}}
                                          wrapperClass=""
                                          visible={true}
                                          ariaLabel='oval-loading'
                                          secondaryColor="#4fa94d"
                                          strokeWidth={2}
                                          strokeWidthSecondary={2}
                                      />  </div>}
                {data && (
  <div>
    <h2>Assessment Alignment Results from {filename}</h2>
    <label htmlFor="assessment-dropdown">Select Question:</label>
    <select
      id="assessment-dropdown"
      value={selectedKey} // Controlled dropdown
      onChange={handleOptionChange}
    >
      {Object.keys(data.GENERATED_ANSWERS).map((key) => (
        <option key={key} value={key}>
         {key}
        </option>
      ))}
    </select>

    {selectedKey && (
      <div className="question-section">
        <h4>{selectedKey}: {questions[selectedKey]}</h4>

        <div className="answer-section">
          <p>
            <strong>Answer:</strong> {data.GENERATED_ANSWERS[selectedKey]?.ANSWER || "N/A"}
          </p>
          <p>
            <strong>Analysis:</strong> {data.GENERATED_ANSWERS[selectedKey]?.ANALYSIS || "N/A"}
          </p>
          <p>
            <strong>Score:</strong> {data.GENERATED_ANSWERS[selectedKey]?.SCORE || "N/A"}
          </p>
        </div>
      </div>
    )}

    <div className="average-score">
      <p>
        <strong>Average Score:</strong> {data["AVERAGE SCORE"] || "N/A"}
      </p>
      


      {basic && (
        <div className="flexing">
          <p><strong>Company Name:</strong> {basic.COMPANY_NAME || "N/A"}</p>
          <p><strong>Company Sector:</strong> {basic.COMPANY_SECTOR || "N/A"}</p>
          <p><strong>Company Location:</strong> {basic.COMPANY_LOCATION || "N/A"}</p>
        </div>
      )}
    </div>
  </div>
)}
    </div>
  );
};

export default Assessment;
