import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../static/Dashboard.css";
import "../static/Secondchatbox.css";
import { useAuth } from "../AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import asknaturelogo from "../static/images/asknaturelogo.png";
import { faBars, faUser, faHome, faExclamationTriangle, faDatabase } from "@fortawesome/free-solid-svg-icons";
import paperPlane from '../static/images/paper-plane.png'; // Import the image
import { Oval } from 'react-loader-spinner';
import ChatAssessment from "../components/ChatAssessment";

const NewAlignment = () => {
    const navigate = useNavigate();
    const { logout } = useAuth();

    useEffect(() => {
        document.title = 'Dashboard - ChatNFD';
    }, []);

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    useEffect(() => {
        const $button = document.querySelector('#sidebar-toggle');
        const $wrapper = document.querySelector('#wrapper');

        const handleToggle = (e) => {
            e.preventDefault();
            $wrapper.classList.toggle('toggled');
        };

        $button.addEventListener('click', handleToggle);

        // Cleanup function to remove the event listener
        return () => {
            $button.removeEventListener('click', handleToggle);
        };
    }, []);

    return (
        <div className="dashboard-container">
            {/* Navigation Bar */}
            <div id="wrapper">
                <aside id="sidebar-wrapper">
                    <div className="sidebar-brand">
                    <a href="/dashboard">  <img src={asknaturelogo} alt="AskNature Logo" 
        style={{ width: '150px', height: 'auto' }} 
/>
    </a>                    </div>
                    <ul className="sidebar-nav">
                        <li>
                            <a href="/"> <FontAwesomeIcon icon={faHome} className='leftmargin'/> Home</a>
                        </li>
                        <li>
                            <a href="/disclaimer"> <FontAwesomeIcon icon={faExclamationTriangle} className='leftmargin'/> Disclaimer</a>
                        </li>
                        <li className="active">
                            <a href="/database"> <FontAwesomeIcon icon={faDatabase} className='leftmargin'/> Database</a>
                        </li>
                        <li>
                            <Link onClick={handleLogout}> <FontAwesomeIcon icon={faUser} className='leftmargin' /> Logout</Link>
                        </li>
                    </ul>
                </aside>

                <div id="navbar-wrapper">
                    <nav className="navbar navbar-inverse">
                        <div className="container-fluid">
                            <div className="navbar-header">
                                <a href="#" className="navbar-brand" id="sidebar-toggle"><span className="navbar-toggler-icon"></span></a>
                            </div>
                        </div>
                    </nav>
                </div>

                {/* Main Content with Tabs */}
                <main className="col-md-10 ms-sm-auto col-lg-12 px-md-4">
                    {/* Tab Navigation */}
                    <ChatAssessment/>
                </main>
            </div>
        </div>
    );
};

export default NewAlignment;
