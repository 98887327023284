import React, { useState } from 'react';
import { Oval } from 'react-loader-spinner';
import FileChatApp from './NewFileChat';  // Correct the file path if needed
import { Link, useNavigate } from 'react-router-dom';

const FileuploadDiv = () => {
    const [files, setFiles] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pdf, setPdf] = useState(true);

    const [newMessage, setNewMessage] = useState('');
    const [error, setError] = useState(null);
    const [showOptions, setShowOptions] = useState(false);
    const [showChatApp, setShowChatApp] = useState(false);
    const [taskId, setTaskId] = useState(null);
    const useremail = localStorage.getItem('useremail');
    const sessionid = localStorage.getItem('token');
    const navigate = useNavigate();
    
    // Handle file upload
    const handleFileUpload = async (selectedFiles) => {
        const formData = new FormData();
        for (const file of selectedFiles) {
            formData.append('file', file);
        }
        formData.append('chunk_size', '512');
        formData.append('question_answered_extractor', 'false');
        formData.append('useremail', useremail);
        formData.append('sessionid', sessionid);

        setLoading(true);
        setProcessing(true);
        setError(null);
        try {
            const response = await fetch('https://asknature.ai/userupload/upload-or-fetch-pdf/', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to upload files');
            }

            const responseData = await response.json();
            console.log(responseData);
            const taskId = responseData.task_id;
            setTaskId(taskId);
            localStorage.setItem('taskId', taskId);

             const refileName = responseData.filename;
            localStorage.setItem('filename', refileName);
            console.log(refileName);

            setNewMessage('Files uploaded successfully, processing started...');

            // Start polling for processing status
            checkStatus(taskId);
            setFiles([]);
        } catch (err) {
            console.error(err);
            setError('Failed to upload files');
            setProcessing(true);
        } finally {
            setLoading(true);
        }
    };

    // Polling the task status
    const checkStatus = async (taskId) => {
        try {
            const response = await fetch(`https://asknature.ai/userupload/processing-status/${taskId}`);
            const data = await response.json();
            if (data.status === "Completed") {
                setNewMessage('Files processed successfully, now you can choose what to do next.');
                setShowOptions(true);
                setProcessing(false);
                setLoading(false);
                setPdf(false)
            } else if (data.status.startsWith("Failed")) {
                setError(`Processing failed: ${data.status}`);
                setProcessing(false);
                setLoading(false);
            } else {
                setTimeout(() => checkStatus(taskId), 5000); // Poll every 5 seconds
            }
        } catch (err) {
            console.error(err);
            setError('Failed to check processing status');
            setProcessing(false);
            setLoading(false);
        }
    };

    // Handle user selecting "Ask from Document"
    const handleAskFromDocument = () => {
        setShowOptions(false);
        setShowChatApp(true);
    };

    // Handle user selecting "Go to Alignment"
    const handleAlignmentAPI = () => {
        setNewMessage('Redirecting to Alignment...');
        const sessionid = localStorage.getItem('token');

        const link = `/assessment/${sessionid}`;
        navigate(link);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFiles = event.dataTransfer.files;
        if (droppedFiles.length > 0) {
            handleFileUpload(droppedFiles);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };


    return (
        <div className="AppContainer">
            {/* Conditionally render the file upload section */}
            {!showChatApp && (
                <>
             
                    <h1>Upload a PDF and Chat</h1>
                    <p> Upload a PDF (Corporate Sustainability Report of a company), Assess the
                    alignment of CSR with TNFD regulations and Chat</p>
                    {loading &&    <div className='loadingbutton'><Oval height={100} width={100} color="#4fa94d" />
                    </div>}
                    {/* File Upload */}

                    {pdf && (
                    <div 
                className="file-drop-area"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                 role="button"
                aria-label="Drag and Drop or Click to Upload PDF Files"
                onClick={() => document.getElementById("file-input").click()} // Make the div clickable

            >
                <p>Drag your PDF files here or click to upload</p>
                <input
                    type="file"
                    multiple
                    accept="application/pdf"
                    onChange={(e) => handleFileUpload(e.target.files)}
                    className="file-input"
                    id="file-input"

                />
            </div>
                    )}
                    
               
                    {error && <div className="error">{error}</div>}
                    {newMessage && <div className="newMessage">{newMessage}</div>}

                    {/* Show options after successful processing */}
                    {showOptions && (
                        <div className="options-container">

                            <button onClick={handleAlignmentAPI} className="btn mybutton">Reports Alignment with TNFD</button>
                            <button onClick={handleAskFromDocument} className="btn mybutton">Ask from Reports</button>
                        </div>
                    )}
                </>
            )}

            {/* Show FileChatApp when showChatApp is true */}
            {showChatApp && <FileChatApp />}
        </div>
    );
};

export default FileuploadDiv;
